export const FIREBASE_CONFIG= {
    apiKey: "AIzaSyB-39Cl06V_AOclsMjaSUFsCVpWBec7uUk",
    authDomain: "bentogram-g06-21.firebaseapp.com",
    databaseURL: "https://bentogram-g06-21.firebaseio.com",
    projectId: "bentogram-g06-21",
    storageBucket: "bentogram-g06-21.appspot.com",
    messagingSenderId: "399377794570",
    appId: "1:399377794570:web:9d1e66eef41f07b40101f3"
  };


